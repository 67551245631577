export const BLOG = `The revolution is upon us: Generative AI (GenAI) is changing the nature of work and play, enabling new interactive experiences that have never been possible before. However, the current, large-model approach that Cloud AI providers are taking is fundamentally flawed for video games. Game developers currently implementing GenAI in their games are struggling due the outrageous costs and lack of fine-grained level of control caused by Cloud AI providers.

We've built something different at Studio Atelico: an AI Engine for video games that solves these problems by running on the player’s device and giving the developer a modular toolset to integrate in Unreal, Unity, and Godot. Our Atelico AI Engine finally makes GenAI possible for every game developer.

## Games are Hard, AI Shouldn’t Make it Harder

I learned to code when I was 6 to create my own version of Pac-Man to run on my Amiga. I grew up immersed in games like Populus, SimCity, and Dungeon Keeper and dedicated my career to AI with the dream of one day applying it to video games. The moment I saw the power of GPT-2, I knew that the tech was finally ready; GenAI was going to revolutionize games for developers and players alike.

At this year's Game Developer Conference in San Francisco, I talked with more than 40 game developers trying to add GenAI to their games. Every single one was struggling with the task.

For some, the potential costs of running AI in their games would bankrupt them with just a thousand daily players. Others tried to create mischievous or evil characters, only to be shut down by safety-constrained AI models. Others couldn’t get the rate limits high enough and saw their NPCs suddenly go silent during gameplay. The developers of premium games with a one-time purchase business model told me they couldn’t possibly bear the weight of costs that accompany a Cloud AI solution that scales with each gameplay interaction.

All of this opened my eyes to the fact that GenAI will revolutionize the gaming industry if and only if we can put it on-device, under the developer's direct control, without the back-breaking cloud costs and limitations.

## Atelico AI Engine \= Problem Solved for developers

Studio Atelico was founded to solve these problems for developers and pave the way for emergent game design. I assembled my dream team of AI specialists and game industry veterans from Uber, Meta, Creative Assembly, Stanford, and MIT. Together, we built an engine that is modular, on-device (cost effective), and puts control into the hands of developers.

## Atelico AI Engine is Modular

In order to make Atelico AI Engine deeply customizable for game developers, we modularized the architecture with a leaky abstraction. That way, if a developer is building a simulation game that is based on autonomous NPC agents, they can use components from the agentic layer. On the other hand, if they need AI to direct or adapt the gameplay to the actions of the player in other ways, they can easily compose the lower level components to build what they need.

## On-Device AI that is Powerful AND Cost Effective

Usually, AI models that run on-device alone do not match the performance of large cloud models. This can result in a worse experience for players. Leveraging the deep experience our team has in training AI models, we developed a first generation of small models that are specifically tuned for video games, effectively bridging the gap with larger models on gaming specific tasks.

![][image5.png]

Perhaps just as importantly, running on-device reduces costs by five orders of magnitude compared to Cloud or SaaS-based solutions. Our models are also optimized to ensure device resources aren’t heavily impacted.

[Cloud AI providers](https://inworld.ai/blog/transforming-gaming-experiences-with-edge-and-on-device-ai) would have you believe that cloud-based solutions are the only way forward. However, running on-device with game-specific models empowers developers to avoid turning every game into a subscription service — the last thing developers and players want.

## Atelico AI Engine: Unparalleled Control

The Atelico AI Engine is easy to embed in game engines and provides full control and flexibility thanks to its modular design. It’s a tool for harnessing AI to craft expansive worlds that respond to players in ways never thought possible. Developers can design with complete creative control, bringing players into experiences that are unique, where every choice feels real, every character feels alive, and the game world is truly reactive.

## GARP Tech Demo: A village powered by local AI

To showcase what you can do with the _Atelico AI Engine_, we built a tech demo inspired by the Stanford paper “[Generative Agents: Interactive Simulacra of Human Behavior](https://arxiv.org/abs/2304.03442)”. Unlike the original, which ran in discrete turns rather than in real time and cost \~$500 per game day using OpenAI, our demo—called the Generative Agents Realtime Playground (GARP)—runs locally in real time on a Nvidia RTX 3090 at no cost. It features a simulated village with over 20 characters living in it.
![][image4.gif]

In GARP, villagers are autonomous agents that plan their day, make decisions, take actions to complete objectives, form memories, engage in conversation, and coordinate with each other to complete tasks. All behavior is based on each character’s background, personality, and the memories of their experiences. Characters reflect on their experiences and learn, so every day is different.

These mechanics lead to emergent behavior: just adding to a character’s memory that today is their birthday results in them spreading the news and the entire village organizing and attending a birthday party\! This emergent behaviour is truly remarkable, enabling it with traditional AI techniques would require a lot more than one line of text.

_![][image1.gif]_

Just for fun, we brought Rick and Morty to life in GARP\! Narrative designers can bring any of their characters to life using the Atelico AI Engine.

![][image2.gif]![][image3.gif]

GARP is truly state of the art, as running this level of simulation on a single GPU is unprecedented, but this demo is only just a glimpse into what the Atelico AI Engine can do.

## The next frontier in gaming is powered by AI

Our goal is to abstract the technical complexity of GenAI to empower game developers. The Atelico AI Engine today can already enrich simulation games like The Sims, Animal Crossing, Stardew Valley or Rimworld, but it's not limited to this genre. Its modular design makes it possible for game developers to use it for different genres of games that may not require agentic behavior: think of an engaging diplomacy system in a strategy game where the player needs to negotiate with other factions, or a narrator voice that reacts dynamically to the actions of the player, like the bard Jaskier/Dandelion commenting dynamically on Geralt's deeds in _The Witcher_.

Atelico AI Engine is the perfect tool for developers to invent entirely new game genres. We foresee a Cambrian explosion of creativity, where our AI tools empower designers to build more immersive experiences and truly widen the horizon of what’s possible. The only limitation is the developer's imagination\!

## Let’s redefine what games can be, together

You are invited to join us on this journey. Whether you're a game developer, industry innovator, or passionate gamer, we’d love to hear from you and explore how we can build the future of gaming together.

- **Subscribe to our newsletter** to receive the latest updates on our product developments and upcoming features.
- [**Request a demo**](mailto:hello@atelico.studio) to experience our AI technology firsthand and see how it can empower your game ideas.
- [**Send us your thoughts**](mailto:hello@atelico.studio): share your ideas with us — we’d love to hear from you\!
- **Learn more** by watching this in-depth video of GARP and the Atelico AI Engine’s current functionalities.

{youtube:xT_vi2maxuM}

We’re proud to already be collaborating with talented developers to turn their creative vision into reality while we refine our engine to make it available to everyone soon. Our goal is to challenge what’s possible and inspire developers to dream up new games with AI that we can bring to life together. We have many more exciting announcements on the way, so stay tuned for more\!
`;

export default BLOG;
