import React from "react";
import { Box, styled } from "@mui/material";
import { Link } from "react-router-dom";

const LogoContainer = styled(Box, {
  shouldForwardProp: (prop) => prop !== "mobile",
})(({ theme, mobile }) => ({
  width: mobile ? "60px" : "80px",
  height: mobile ? "60px" : "80px",
  marginTop: mobile ? "1rem" : "3rem",
  marginBottom: mobile ? "1rem" : "2rem",
  transition: "all 0.3s ease",

  [theme.breakpoints.up("md")]: {
    width: mobile ? "60px" : "100px",
    height: mobile ? "60px" : "100px",
    marginTop: mobile ? "1rem" : "4rem",
  },

  [theme.breakpoints.up("lg")]: {
    width: mobile ? "60px" : "120px",
    height: mobile ? "60px" : "120px",
    marginTop: mobile ? "1rem" : "5rem",
  },
}));

const VideoLogo = styled("video")({
  width: "100%",
  height: "100%",
  objectFit: "cover",
});

const SidebarLogo = ({ mobile }) => {
  return (
    <LogoContainer mobile={mobile}>
      <Link to="/">
        <VideoLogo autoPlay loop muted playsInline src="/logo_corner_web.mp4" />
      </Link>
    </LogoContainer>
  );
};

export default SidebarLogo;
