import React from "react";
import { Box, Link, styled } from "@mui/material";

const IconLink = styled(Link)(({ theme }) => ({
  color: theme.palette.text.primary,
  transition: "opacity 0.3s ease",
  display: "flex",
  alignItems: "center",
  "&:hover": {
    opacity: 0.7,
  },
}));

const IconWrapper = styled(Box)({
  width: "24px",
  height: "24px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
});

const SocialIcons = ({ containerSx = {} }) => {
  return (
    <Box
      sx={{
        display: "flex",
        gap: 3,
        ...containerSx,
      }}
    >
      <IconLink href="mailto:hello@atelico.studio" aria-label="Email">
        <IconWrapper>
          <svg viewBox="0 0 24 24" fill="currentColor">
            <path d="M20 4H4c-1.1 0-1.99.9-1.99 2L2 18c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2zm0 4l-8 5-8-5V6l8 5 8-5v2z" />
          </svg>
        </IconWrapper>
      </IconLink>
      <IconLink
        href="https://x.com/StudioAtelico"
        target="_blank"
        aria-label="Twitter"
      >
        <IconWrapper>
          <svg viewBox="0 0 24 24" fill="currentColor">
            <path d="M18.244 2.25h3.308l-7.227 8.26 8.502 11.24H16.17l-5.214-6.817L4.99 21.75H1.68l7.73-8.835L1.254 2.25H8.08l4.713 6.231zm-1.161 17.52h1.833L7.084 4.126H5.117z" />
          </svg>
        </IconWrapper>
      </IconLink>

      <IconLink
        href="https://www.youtube.com/@StudioAtelico"
        target="_blank"
        aria-label="YouTube"
      >
        <IconWrapper>
          <svg viewBox="0 0 24 24" fill="currentColor">
            <path d="M23.498 6.186a3.016 3.016 0 0 0-2.122-2.136C19.505 3.545 12 3.545 12 3.545s-7.505 0-9.377.505A3.017 3.017 0 0 0 .502 6.186C0 8.07 0 12 0 12s0 3.93.502 5.814a3.016 3.016 0 0 0 2.122 2.136c1.871.505 9.376.505 9.376.505s7.505 0 9.377-.505a3.015 3.015 0 0 0 2.122-2.136C24 15.93 24 12 24 12s0-3.93-.502-5.814zM9.545 15.568V8.432L15.818 12l-6.273 3.568z" />
          </svg>
        </IconWrapper>
      </IconLink>

      <IconLink
        href="https://bsky.app/profile/studioatelico.bsky.social"
        target="_blank"
        aria-label="Bluesky"
      >
        <IconWrapper>
          <svg viewBox="0 0 600 530" fill="currentColor">
            <path d="m135.72 44.03c66.496 49.921 138.02 151.14 164.28 205.46 26.262-54.316 97.782-155.54 164.28-205.46 47.98-36.021 125.72-63.892 125.72 24.795 0 17.712-10.155 148.79-16.111 170.07-20.703 73.984-96.144 92.854-163.25 81.433 117.3 19.964 147.14 86.092 82.697 152.22-122.39 125.59-175.91-31.511-189.63-71.766-2.514-7.3797-3.6904-10.832-3.7077-7.8964-0.0174-2.9357-1.1937 0.51669-3.7077 7.8964-13.714 40.255-67.233 197.36-189.63 71.766-64.444-66.128-34.605-132.26 82.697-152.22-67.108 11.421-142.55-7.4491-163.25-81.433-5.9562-21.282-16.111-152.36-16.111-170.07 0-88.687 77.742-60.816 125.72-24.795z" />
          </svg>
        </IconWrapper>
      </IconLink>

      <IconLink
        href="https://www.linkedin.com/company/106115656/admin/dashboard/"
        target="_blank"
        aria-label="LinkedIn"
      >
        <IconWrapper>
          <svg viewBox="0 0 24 24" fill="currentColor">
            <path d="M20.447 20.452h-3.554v-5.569c0-1.328-.027-3.037-1.852-3.037-1.853 0-2.136 1.445-2.136 2.939v5.667H9.351V9h3.414v1.561h.046c.477-.9 1.637-1.85 3.37-1.85 3.601 0 4.267 2.37 4.267 5.455v6.286zM5.337 7.433c-1.144 0-2.063-.926-2.063-2.065 0-1.138.92-2.063 2.063-2.063 1.14 0 2.064.925 2.064 2.063 0 1.139-.925 2.065-2.064 2.065zm1.782 13.019H3.555V9h3.564v11.452zM22.225 0H1.771C.792 0 0 .774 0 1.729v20.542C0 23.227.792 24 1.771 24h20.451C23.2 24 24 23.227 24 22.271V1.729C24 .774 23.2 0 22.222 0h.003z" />
          </svg>
        </IconWrapper>
      </IconLink>
    </Box>
  );
};

export default SocialIcons;
