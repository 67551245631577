import React, { useState } from "react";
import { Box, Button, Typography, styled } from "@mui/material";
import { useSnackbar } from "notistack";

const SubscribeInput = styled("input")(({ theme }) => ({
  width: "18em",
  height: "3em",
  backgroundColor: theme.palette.background.paper,
  border: "1px solid transparent",
  borderRadius: "0.3em",
  margin: 0,
  padding: "0.5em 0 0.5em 1.5em",
  fontSize: "0.9em",
  transition: "border-color 0.3s",
  boxShadow: "0 30px 30px -20px #cccccc",
  "&:hover": {
    borderColor: "#e3e3e3",
  },
  "&:focus": {
    borderColor: "#0885ff",
    outline: "none",
  },
  //   [theme.breakpoints.down("sm")]: {
  //     width: "100%", // Full width on mobile
  //     fontSize: "0.8em",
  //   },
}));

const Subscribe = ({ containerSx = {} }) => {
  const [email, setEmail] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const handleSubscribe = () => {
    if (!email) return;
    setIsLoading(true);

    const params = new URLSearchParams({
      u: "78a558343d2959b9da6a200db",
      id: "ee77010ee8",
      EMAIL: email,
      c: "handleCallback",
    });

    window.handleCallback = (data) => {
      setIsLoading(false);
      if (data.result === "success") {
        enqueueSnackbar("Thanks, you're subscribed!", { variant: "success" });
        setEmail("");
      } else {
        enqueueSnackbar(data.msg || "Subscription failed", {
          variant: "error",
        });
      }
    };

    const script = document.createElement("script");
    script.src = `https://studio.us17.list-manage.com/subscribe/post-json?${params}`;
    document.body.appendChild(script);
  };

  return (
    <Box sx={{ ...containerSx }}>
      <Typography
        variant="body1"
        sx={{
          color: "text.secondary",
          maxWidth: "60ch",
          fontSize: {
            xs: "0.8em",
            md: "0.9em",
            lg: "1em",
          },
          mb: 2,
        }}
      >
        Stay updated on our progress, enroll in limited spots for early access
      </Typography>

      <Box sx={{ display: "flex", gap: 1, flexWrap: "wrap" }}>
        <SubscribeInput
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          onKeyPress={(e) => e.key === "Enter" && handleSubscribe()}
          placeholder="Your email"
          disabled={isLoading}
        />

        <Button
          onClick={handleSubscribe}
          variant="contained"
          disabled={isLoading}
          sx={{
            fontSize: "0.8em",
            m: 0,
            p: 2,
          }}
        >
          {isLoading ? "Subscribing..." : "Subscribe"}
        </Button>
      </Box>
    </Box>
  );
};

export default Subscribe;
