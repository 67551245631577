import React from "react";
import {
  Box,
  Button,
  Container,
  Typography,
  Link,
  styled,
  useTheme,
} from "@mui/material";
import { useEffect, useRef, useState } from "react";
import {
  motion,
  useScroll,
  useTransform,
  AnimatePresence,
} from "framer-motion";
import AnimatedParentDiv from "../components/AnimatedParentDiv";
import AnimatedNavLink from "../components/AnimatedNavLink";
import SidebarLogo from "../components/SidebarLogo";
import Subscribe from "../components/Subscribe";
import SocialIcons from "../components/SocialIcons";

import { BLOG_ANNOUNCE_SLUG, BLOG_POSTS } from "./BlogPost";

import { useSnackbar } from "notistack";

const CONTENT = {
  company: {
    name: "Studio Atelico",
    email: "hello@atelico.studio",
  },
  hero: {
    line1: {
      start: "We Build",
      end: "AI Engines",
    },
    line2: {
      start: "For",
      end: "Video Games",
    },
    line3: {
      start: "That Run",
      end: "On-Device",
    },
  },
  techDemo: {
    title: "Generative Agents Real-time Playground",
    description:
      "To showcase the Atelico AI Engine, we built a tech demo inspired by Stanford's “Generative Agents: Interactive Simulacra of Human Behavior.” Unlike the original, which ran in turns and cost ~$500 per game day, our demo—Generative Agents Realtime Playground (GARP)—runs locally in real time on an Nvidia RTX 3090 at no cost, simulating a village with 20+ characters.",
    old_description:
      "Inpired by [Generative Agents paper link](https://arxiv.org/abs/2304.03442) we created a Generative Agents Realtime Playground, a village simulation with agents that have different personalities, plan, collect memories, make autonomous decision, chat and learn. It runs 20 characters in real time on a single Nvidia 3090",
    videoUrl: "https://youtu.be/xT_vi2maxuM",
    post_description: "Subscribe to get early access to our tech demo.",
    linkName: "Tech Demo",
  },
  subscribe: {
    title: "Join our mailing list",
    // description:
    //   "Stay updated on our progress, enroll in limited spots for early access",
  },
  blog: {
    title: "Blog: Latest Posts",
    posts: BLOG_POSTS,
  },
  about: {
    title: "Who We Are",
    description: `We are AI and gaming industry veterans from Uber, Meta, and Creative Assembly building an on-device AI Engine for Video Games from the ground up. 
    Our technology enables gaming experiences where characters possess lifelike intelligence, 
    interact naturally with players, and reason within the game world. All without breaking the bank on Cloud AI.`,
    // highlights: [
    //   "Proprietary gaming-specific LLMs",
    //   "Runs directly on players' devices",
    //   "Dramatically reduced costs",
    // ],
  },
  // Social links - commented out ones not yet available
  social: {
    youtube: "https://youtu.be/xT_vi2maxuM",
    // twitter: "https://twitter.com/studioatelico",
    // github: "https://github.com/atelico",
    // linkedin: "https://linkedin.com/company/studioatelico"
  },
};

const SubscribeSection = React.forwardRef(({ children, ...props }, ref) => {
  return (
    <Box
      {...props}
      ref={ref}
      component="section"
      sx={{
        minHeight: "100vh",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        paddingRight: "5%",
        width: "100%",
      }}
    >
      <Subscribe />
      <SocialIcons containerSx={{ mt: 6 }} />
    </Box>
  );
});
// const SubscribeSection = React.forwardRef(({ children, ...props }, ref) => {
//   const [email, setEmail] = useState("");
//   const [isLoading, setIsLoading] = useState(false);
//   const { enqueueSnackbar } = useSnackbar();

//   const handleSubscribe = () => {
//     if (!email) return;
//     setIsLoading(true);

//     const params = new URLSearchParams({
//       u: "78a558343d2959b9da6a200db",
//       id: "ee77010ee8",
//       EMAIL: email,
//       c: "handleCallback",
//     });

//     window.handleCallback = (data) => {
//       setIsLoading(false);
//       if (data.result === "success") {
//         enqueueSnackbar("Thanks, you're subscribed!", { variant: "success" });
//         setEmail("");
//       } else {
//         enqueueSnackbar(data.msg || "Subscription failed", {
//           variant: "error",
//         });
//       }
//     };

//     const script = document.createElement("script");
//     script.src = `https://studio.us17.list-manage.com/subscribe/post-json?${params}`;
//     document.body.appendChild(script);
//   };

//   return (
//     <Box
//       {...props}
//       ref={ref}
//       component="section"
//       sx={{
//         minHeight: "100vh",
//         display: "flex",
//         flexDirection: "column",
//         justifyContent: "center",
//         paddingRight: "5%",
//         width: "100%",
//       }}
//     >
//       <Typography variant="body1" sx={{ mb: 2 }}>
//         {CONTENT.subscribe.description}
//       </Typography>

//       <Box sx={{ display: "flex", gap: 1 }}>
//         <SubscribeInput
//           type="email"
//           value={email}
//           onChange={(e) => setEmail(e.target.value)}
//           onKeyPress={(e) => e.key === "Enter" && handleSubscribe()}
//           placeholder="Your email"
//           disabled={isLoading}
//         />

//         <Button
//           onClick={handleSubscribe}
//           variant="contained"
//           disabled={isLoading}
//         >
//           {isLoading ? "Subscribing..." : "Subscribe"}
//         </Button>
//       </Box>
//     </Box>
//   );
// });

// Optional: Add display name for debugging
SubscribeSection.displayName = "SubscribeSection";

const AboutUsSection = styled(Box)({
  minHeight: "100vh",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center", // Center vertically like other sections
  alignItems: "flex-start",
  paddingRight: "5%",
  width: "100%", // Ensures full width
});

const SubscribeInput = styled("input")(({ theme }) => ({
  width: "18em",
  height: "3em",
  backgroundColor: theme.palette.background.paper,
  border: "1px solid transparent",
  borderRadius: "0.3em",
  margin: 0,
  padding: "0.5em 0 0.5em 1.5em",
  fontSize: "0.9em",
  transition: "border-color 0.3s",
  boxShadow: "0 30px 30px -20px #cccccc",
  "&:hover": {
    borderColor: "#e3e3e3",
  },
  "&:focus": {
    borderColor: "#0885ff",
    outline: "none",
  },
}));

const Navigation = styled("nav")(({ theme }) => ({
  width: "42%",
  height: "100vh",
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  position: "sticky",
  top: 0,
  paddingLeft: "5%",
  [theme.breakpoints.down("sm")]: {
    display: "none", // Hide the navigation sidebar on mobile
  },
}));

const MobileNavigation = styled(Box)(({ theme }) => ({
  display: "none", // Hidden by default on desktop

  [theme.breakpoints.down("sm")]: {
    display: "flex",
    flexDirection: "column",
    gap: "0.5em",
    padding: "2em 5%",
    borderBottom: "1px solid rgba(17, 17, 17, 0.1)",
  },
}));

const NavLink = styled(Link)(({ theme, active }) => ({
  color: "#111111",
  letterSpacing: "-0.025em",
  paddingBottom: "3px",
  fontSize: "1em",
  fontWeight: 900,
  variant: "nav",
  opacity: active ? 1 : 0.9,
  textDecoration: "none",
  transition: "opacity 0.3s",
  display: "inline-block", // Add this to ensure transform-origin works properly
  transformOrigin: "left center", // Add this to make scaling start from left
  "&:hover": {
    color: theme.palette.primary.main,
    opacity: 1,
    textDecoration: "none",
  },
}));

const ProjectLink = styled(Link)(({ theme }) => ({
  display: "grid",
  gridTemplateColumns: "auto auto auto",
  alignItems: "center",
  gap: "0.5em",
  color: theme.palette.text.primary,
  padding: "1em 1.5em",
  fontSize: "0.65em",
  fontWeight: 500,
  borderBottom: "1px solid rgba(17, 17, 17, 0.1)",
  borderRight: "1px solid rgba(17, 17, 17, 0.1)",
  textDecoration: "none",
  transition: "all 0.3s ease",

  "& .project-title": {
    color: theme.palette.text.primary,
    fontSize: "1em",
    fontWeight: 500,
  },

  "& .project-tag": {
    color: theme.palette.text.secondary,
    fontSize: "1em",
    fontWeight: 400,
  },

  "&:hover": {
    backgroundColor: "rgba(17, 17, 17, 0.03)",
    color: theme.palette.text.primary,
    textDecoration: "none",
  },

  "&:nth-of-type(3n)": {
    borderRight: "none",
  },

  [theme.breakpoints.down("sm")]: {
    "&:nth-of-type(3n)": {
      borderRight: "1px solid rgba(17, 17, 17, 0.1)",
    },
    "&:nth-of-type(2n)": {
      borderRight: "none",
    },
  },

  [theme.breakpoints.down("xs")]: {
    borderRight: "none",
  },
}));

const ProjectImage = styled("img")({
  width: "15em",
  height: "9em",
  objectFit: "cover",
  borderRadius: "2px",
  opacity: 0,
  position: "absolute",
  left: "-15em",
  boxShadow: "0 40px 80px -60px #000",
  zIndex: -1,
});

const TechDemoSection = styled(Box)({
  minHeight: "100vh",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  paddingRight: "5%", // Add right padding for balance
});

const BlogSection = styled(Box)({
  minHeight: "100vh",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  paddingRight: "5%",
});

const BlogLink = styled(Link)(({ theme }) => ({
  color: theme.palette.text.primary,
  textDecoration: "none",
  padding: "1.5em 0",
  // borderBottom: "1px solid rgba(17, 17, 17, 0.1)",
  transition: "opacity 0.3s",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",

  "&:hover": {
    opacity: 0.7,
  },
}));

export default function HomePage() {
  const theme = useTheme();
  const [activeSection, setActiveSection] = useState("hero");
  const [animationStates, setAnimationStates] = useState({
    techDemo: false,
    subscribe: false,
    blog: false,
    aboutUs: false,
  });
  const techDemoRef = useRef(null);
  const subscribeRef = useRef(null);
  const oneMoreRef = useRef(null);
  const blogRef = useRef(null);

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY + window.innerHeight * 0.5;

      // Check if sections are in view and trigger animations
      if (
        techDemoRef.current &&
        scrollPosition >= techDemoRef.current.offsetTop &&
        !animationStates.techDemo
      ) {
        setAnimationStates((prev) => ({ ...prev, techDemo: true }));
      }

      if (
        blogRef.current &&
        scrollPosition >= blogRef.current.offsetTop &&
        !animationStates.blog
      ) {
        setAnimationStates((prev) => ({ ...prev, blog: true }));
      }

      if (
        subscribeRef.current &&
        scrollPosition >= subscribeRef.current.offsetTop &&
        !animationStates.subscribe
      ) {
        setAnimationStates((prev) => ({ ...prev, subscribe: true }));
      }

      if (
        oneMoreRef.current &&
        scrollPosition >= oneMoreRef.current.offsetTop &&
        !animationStates.aboutUs
      ) {
        setAnimationStates((prev) => ({ ...prev, aboutUs: true }));
      }

      // Update active section
      if (scrollPosition < techDemoRef.current?.offsetTop) {
        setActiveSection("hero");
      } else if (scrollPosition < oneMoreRef.current?.offsetTop) {
        setActiveSection("techDemo");
      } else if (scrollPosition < subscribeRef.current?.offsetTop) {
        setActiveSection("aboutUs");
      } else {
        setActiveSection("subscribe");
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [animationStates]);

  return (
    <Box
      component="main"
      className="main"
      sx={{ display: "flex", alignItems: "flex-start" }}
    >
      <Navigation>
        <SidebarLogo />

        <Box
          sx={{
            flex: 1,
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            gap: "0.1em",
          }}
        >
          <AnimatedNavLink
            duration={0.8}
            delay={0.2} // Increased delay to appear after logo
            active={activeSection === "techDemo"}
            animationState={animationStates.techDemo}
            href="#techDemo"
          >
            {CONTENT.techDemo.linkName}
          </AnimatedNavLink>
          <AnimatedNavLink
            duration={0.8}
            delay={0.35} // Increased delay to appear after techDemo
            active={activeSection === "aboutUs"}
            animationState={animationStates.aboutUs}
            href="#about-us"
          >
            About Us
          </AnimatedNavLink>
          <AnimatedNavLink
            duration={0.8}
            delay={0.4} // Increased delay to appear after techDemo
            active={activeSection === "subscribe"}
            animationState={animationStates.subscribe}
            href="#subscribe"
          >
            Subscribe
          </AnimatedNavLink>
        </Box>

        <Box sx={{ pb: "7.5vh", display: "flex", flexDirection: "column" }}>
          <AnimatedNavLink
            duration={0.8}
            delay={0.55} // Increased delay to appear after techDemo
            active={activeSection === "blog"}
            animationState={animationStates.blog}
            // href="#blog"
            href={`/blog/${BLOG_ANNOUNCE_SLUG}`}
          >
            Blog
          </AnimatedNavLink>
        </Box>
      </Navigation>

      <Box
        className="contents"
        sx={(theme) => ({
          width: "58%",
          display: "flex",
          flexDirection: "column",
          [theme.breakpoints.down("sm")]: {
            width: "100%",
            padding: "0 5%", //  horizontal padding for mobile
          },
        })}
      >
        <MobileNavigation>
          <SidebarLogo mobile />

          <NavLink
            href="#techDemo"
            active={activeSection === "techDemo"}
            variant="nav"
          >
            Tech Demo
          </NavLink>
          <NavLink
            href="#about-us"
            active={activeSection === "aboutUs"}
            variant="nav"
          >
            About Us
          </NavLink>
          <NavLink
            href="#subscribe"
            active={activeSection === "subscribe"}
            variant="nav"
          >
            Subscribe
          </NavLink>
          <NavLink
            active={activeSection === "blog"}
            href={`/blog/${BLOG_ANNOUNCE_SLUG}`}
          >
            Blog
          </NavLink>
        </MobileNavigation>

        <Box
          className="section hero"
          sx={{
            minHeight: "100vh",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          <AnimatedParentDiv delay={0.45}>
            <Typography
              variant="h2"
              className="hero-text"
              sx={{ lineHeight: 1.6 }}
            >
              {CONTENT.hero.line1.start}{" "}
              <span style={{ opacity: 0.5 }}>{CONTENT.hero.line1.end}</span>.
            </Typography>
          </AnimatedParentDiv>
          <AnimatedParentDiv delay={0.55}>
            <Typography
              variant="h2"
              className="hero-text"
              sx={{ lineHeight: 1.6 }}
            >
              {CONTENT.hero.line2.start}{" "}
              <span style={{ opacity: 0.5 }}>{CONTENT.hero.line2.end}</span>.
            </Typography>
          </AnimatedParentDiv>
          <AnimatedParentDiv delay={0.7}>
            <Typography
              variant="h2"
              className="hero-text"
              sx={{ lineHeight: 1.6 }}
            >
              {CONTENT.hero.line3.start}{" "}
              <span style={{ opacity: 0.5 }}>{CONTENT.hero.line3.end}</span>.
            </Typography>
          </AnimatedParentDiv>
        </Box>

        <TechDemoSection id="techDemo" ref={techDemoRef}>
          <Box sx={{ mb: 6 }}>
            <Typography
              variant="h4"
              sx={{
                mb: 2,
                fontSize: {
                  xs: "1.4em",
                  md: "1.6em",
                  lg: "1.8em",
                },
                fontWeight: 600,
              }}
            >
              {CONTENT.techDemo.title}
            </Typography>
            <Typography
              variant="body1"
              sx={{
                color: "text.secondary",
                maxWidth: "60ch",
                fontSize: {
                  xs: "0.8em",
                  md: "0.9em",
                  lg: "1em",
                },
                lineHeight: 1.7,
              }}
            >
              {CONTENT.techDemo.description}
            </Typography>
          </Box>

          <Box
            sx={{
              position: "relative",
              width: "100%",
              paddingTop: "56.25%", // 16:9 Aspect Ratio
              borderRadius: "8px",
              overflow: "hidden",
            }}
          >
            <iframe
              src={`${CONTENT.techDemo.videoUrl.replace(
                "youtu.be/",
                "youtube.com/embed/"
              )}`}
              style={{
                position: "absolute",
                top: 0,
                left: 0,
                bottom: 0,
                right: 0,
                width: "100%",
                height: "100%",
                border: "none",
              }}
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
              title={CONTENT.techDemo.title}
            />
          </Box>
        </TechDemoSection>

        {/* <BlogSection id="blog" ref={blogRef}>
          <Typography variant="h4" sx={{ mb: 4 }}>
            {CONTENT.blog.title}
          </Typography>

          {CONTENT.blog.posts.map((post) => (
            <BlogLink key={post.slug} href={post.slug}>
              <Typography variant="h6">{post.title}</Typography>
              <Typography variant="body2" sx={{ opacity: 0.5 }}>
                {post.date}
              </Typography>
            </BlogLink>
          ))}
        </BlogSection> */}
        <AboutUsSection id="about-us" ref={oneMoreRef}>
          <Box className="overflow-hidden" sx={{ mb: 3 }}>
            <Typography variant="h2" className="one-more-title">
              {CONTENT.about.title}
            </Typography>

            <Typography
              variant="body1"
              className="about-us-text"
              sx={{
                color: "text.secondary",
                maxWidth: "60ch",
                fontSize: {
                  xs: "0.8em",
                  md: "0.9em",
                  lg: "1em",
                },
                marginTop: "3em",
                lineHeight: 1.6,
              }}
            >
              {CONTENT.about.description}
            </Typography>
          </Box>
        </AboutUsSection>

        <SubscribeSection id="subscribe" ref={subscribeRef}></SubscribeSection>
      </Box>
    </Box>
  );
}
