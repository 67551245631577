import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import theme from "./theme";
import { SnackbarProvider, MaterialDesignContent } from "notistack";
import { styled } from "@mui/material/styles";
// Pages
import HomePage from "./pages/HomePage";
import BlogPost from "./pages/BlogPost";

const StyledSnackbar = styled(MaterialDesignContent)(() => ({
  "&.notistack-MuiContent-success": {
    backgroundColor: "#222222",
    color: "#f4f4f4",
  },
  "&.notistack-MuiContent-error": {
    backgroundColor: "#444444",
    color: "#f4f4f4",
  },
}));

function App() {
  return (
    <ThemeProvider theme={theme}>
      <SnackbarProvider
        maxSnack={3}
        autoHideDuration={4000}
        style={{ fontSize: "0.9em" }}
        Components={{
          success: StyledSnackbar,
          error: StyledSnackbar,
        }}
      >
        <CssBaseline />
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="/blog/:slug" element={<BlogPost />} />
          </Routes>
        </BrowserRouter>
      </SnackbarProvider>
    </ThemeProvider>
  );
}

export default App;
