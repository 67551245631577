import React from "react";
import { AppBar, Toolbar, Box, Link as MuiLink, styled } from "@mui/material";
import { Link } from "react-router-dom";

const StyledAppBar = styled(AppBar)(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  color: theme.palette.text.primary,
  boxShadow: theme.shadows[0],
  borderBottom: `1px solid ${theme.palette.divider}`,
}));

export const LogoVideo = styled("video")({
  height: "40px",
  width: "40px",
  objectFit: "cover",
});

const AppHeader = () => {
  return (
    <StyledAppBar position="sticky">
      <Toolbar>
        <MuiLink
          component={Link}
          to="/"
          sx={{
            display: "flex",
            alignItems: "center",
            color: "inherit",
            textDecoration: "none",
          }}
        >
          <LogoVideo
            autoPlay
            loop
            muted
            playsInline
            src="/logo_corner_web.mp4"
          />
        </MuiLink>
      </Toolbar>
    </StyledAppBar>
  );
};

export default AppHeader;
