import React, { useState, useEffect } from "react";
import ReactMarkdown from "react-markdown";
import { Box, Container, Typography, styled } from "@mui/material";
import { useParams } from "react-router-dom";
import rehypeRaw from "rehype-raw";
import AppHeader from "../components/AppHeader";
import Subscribe from "../components/Subscribe";
import SocialIcons from "../components/SocialIcons";
import launchPostContent from "./launch_post_latest";

export const BLOG_ANNOUNCE_SLUG = "cloud-ai-for-video-games-is-dead-on-arrival";

export const BLOG_POSTS = [
  {
    title: "Cloud AI for video games is dead on arrival",
    slug: "/blog/cloud-ai-for-video-games-is-dead-on-arrival",
    date: "Jan 2025",
    author: "Piero Molino",
    publishDate: "January 8, 2025",
  },
];

const YouTubeEmbed = ({ videoId }) => (
  <Box
    sx={{
      position: "relative",
      width: "100%",
      paddingTop: "56.25%", // 16:9 Aspect Ratio
      my: 4,
    }}
  >
    <iframe
      style={{
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        border: "none",
        borderRadius: "8px",
      }}
      src={`https://www.youtube.com/embed/${videoId}`}
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      allowFullScreen
      title="Embedded YouTube Video"
    />
  </Box>
);

const ImageRow = styled(Box)(({ theme }) => ({
  display: "flex",
  gap: "1rem",
  justifyContent: "center",
  flexWrap: "wrap",
  margin: "2em auto",

  "& img": {
    flex: "1 1 0",
    minWidth: "200px",
    // maxWidth: "calc(50% - 0.5rem)",
    height: "auto",
    borderRadius: "8px",
  },
}));

const BlogHeader = styled(Box)(({ theme }) => ({
  marginBottom: theme.spacing(2),
  paddingBottom: theme.spacing(2),
  // borderBottom: `1px solid ${theme.palette.divider}`,
}));

const BlogContainer = styled(Container)(({ theme }) => ({
  maxWidth: "800px",
  // padding: "5vh 5%",
  margin: "0 auto",
  [theme.breakpoints.up("md")]: {
    // padding: "10vh 0",
  },
  [theme.breakpoints.up("lg")]: {
    maxWidth: "1000px",
  },
  [theme.breakpoints.up("xl")]: {
    maxWidth: "1200px",
  },
  padding: "2rem",
}));

const StyledImage = styled("img")({
  maxWidth: "100%",
  height: "auto",
  margin: "2em auto", // Updated to center-align
  borderRadius: "8px",
  display: "block",
});

const processContentString = (text) => {
  // Replace all image tags with proper file paths
  const imageTagRegex = /!\[\]\[([^\]]+\.[a-z]+)\]/g;

  const contentWithImages = text.replace(
    imageTagRegex,
    (match, imageIdWithType) => {
      return `![](/announce/${imageIdWithType})`;
    }
  );

  // Clean up any extra newlines and remove the reference definitions
  const finalContent = contentWithImages
    .replace(/^\[([^\]]+)\]:\s*(.+)$/gm, "") // Remove reference definitions
    .replace(/\n{3,}/g, "\n\n");

  return finalContent;
};

export default function BlogPost() {
  const [imageMap, setImageMap] = useState({});
  const { slug } = useParams();
  const [content, setContent] = useState(
    slug === BLOG_ANNOUNCE_SLUG ? processContentString(launchPostContent) : ""
    // ""
  );

  const currentPost = BLOG_POSTS.find((post) => `/blog/${slug}` === post.slug);

  useEffect(() => {
    const fetchContent = async () => {
      try {
        let response;
        if (slug === BLOG_ANNOUNCE_SLUG) {
          // setContent(processContentString(launchPostContent));
          // setContent(launchPostContent);
          // console.log("launch post latest", launchPostContent);
          // // response = await fetch("/launch_post_latest.md");
          return;
        } else {
          throw new Error("Blog post not found");
        }

        // const text = await response.text();
        // console.log("launch post latest", text);

        // setContent(finalContent);
      } catch (error) {
        console.error("Error loading blog post:", error);
        setContent(
          "# Blog Post Not Found\n\nSorry, we couldn't find the blog post you're looking for."
        );
      }
    };

    fetchContent();
  }, [slug]);

  const components = {
    img: ({ node, ...props }) => {
      // Extract the reference ID from the alt text when using reference-style images
      const refMatch = props.src.match(/^\[([^\]]+)\]$/);
      const imgSrc = refMatch ? imageMap[refMatch[1]] : props.src;
      return (
        <StyledImage
          src={imgSrc || props.src}
          alt={props.alt || ""}
          loading="lazy"
        />
      );
    },
    p: ({ node, children }) => {
      const text = node.children[0]?.value || "";
      // console.log("text", text);
      if (text.startsWith("{youtube:") && text.endsWith("}")) {
        const videoId = text.slice(9, -1);
        return <YouTubeEmbed videoId={videoId} />;
      }

      const imageNodes = node.children.filter(
        (child) => child.type === "element" && child.tagName === "img"
      );
      if (imageNodes.length > 1) {
        return <ImageRow>{children}</ImageRow>;
      }

      if (text === "{subscribe}") {
        return <Subscribe containerSx={{ my: 4 }} />;
      }

      // Check if paragraph contains only an image
      if (
        node.children[0]?.type === "element" &&
        node.children[0]?.tagName === "img"
      ) {
        return children;
      }
      return (
        <Typography
          component="p"
          sx={{
            fontSize: {
              xs: "0.7em",
              md: "0.85em",
              lg: "1em",
            },
            lineHeight: 1.7,
            mb: 2,
          }}
        >
          {children}
        </Typography>
      );
    }, // Map h1 (#) to render as h2
    li: ({ children }) => (
      <Typography
        component="li"
        sx={{
          fontSize: {
            xs: "0.8em",
            md: "0.9em",
            lg: "1em",
          },
          lineHeight: 1.7,
          mb: 0.2,
        }}
      >
        {children}
      </Typography>
    ),
    h1: ({ children }) => (
      <Typography
        variant="h1"
        sx={{
          mb: 3,
          mt: 4,
          fontSize: {
            xs: "1.6em",
            md: "1.9em",
            lg: "2.1em",
          },
          fontWeight: 600,
          //   lineHeight: 1.4,
        }}
      >
        {children}
      </Typography>
    ),

    // Map h2 (##) to render as h3
    h2: ({ children }) => (
      <Typography
        variant="h2"
        sx={{
          mb: 2,
          mt: 3,
          fontSize: {
            xs: "1.1em",
            md: "1.3em",
            lg: "1.5em",
          },
          //   fontSize: "1.2em",
          //   fontWeight: 500,
          lineHeight: 1.4,
        }}
      >
        {children}
      </Typography>
    ),

    // Optional: Add h3 (###) if needed
    h3: ({ children }) => (
      <Typography
        variant="h3"
        sx={
          {
            //   mb: 2,
            //   mt: 2,
            //   fontSize: "1em",
            //   fontWeight: 500,
            //   lineHeight: 1.4,
          }
        }
      >
        {children}
      </Typography>
    ),
    strong: ({ node, children }) => {
      const text = children || "";
      if (text === "Subscribe to our newsletter") {
        const [showSubscribe, setShowSubscribe] = useState(false);

        return (
          <strong>
            <Typography
              component="span"
              sx={{
                cursor: "pointer",
                textDecoration: "underline",
                textDecorationThickness: "2px",
                textUnderlineOffset: "4px",
                fontSize: {
                  xs: "1.0em",
                  md: "1.05em",
                  lg: "1.1em",
                },
                // color: "primary.main",
                fontWeight: 600,
                "&:hover": {
                  opacity: 0.7,
                },
              }}
              onClick={() => setShowSubscribe(!showSubscribe)}
            >
              {children}
            </Typography>
            {showSubscribe && (
              <Box sx={{ my: 2 }}>
                <Subscribe
                  containerSx={{
                    p: 3,
                    // backgroundColor: "background.paper",
                    // borderRadius: 1,
                    // boxShadow: 1,
                  }}
                />
              </Box>
            )}
          </strong>
        );
      }
      return <strong>{children}</strong>;
    },
  };

  return (
    <Box
      component="main"
      sx={{ backgroundColor: "background.default", minHeight: "100vh" }}
    >
      <AppHeader />
      <BlogContainer>
        {currentPost && (
          <BlogHeader>
            <Typography
              variant="h1"
              sx={{
                fontSize: {
                  xs: "1.48em",
                  md: "2em",
                  lg: "2.2em",
                },
                fontWeight: 600,
                // mb: 3,
              }}
            >
              {currentPost.title}
            </Typography>
            <Typography
              component="div"
              sx={{
                fontSize: {
                  xs: "0.5em",
                  md: "0.6em",
                  lg: "0.7em",
                },
                color: "text.secondary",
                fontStyle: "italic",
                // mb: 1,
                // ml: 1,
              }}
            >
              {currentPost.publishDate}
            </Typography>
            <Typography
              component="div"
              sx={{
                fontSize: {
                  xs: "0.6em",
                  md: "0.7em",
                  lg: "0.8em",
                },
                fontWeight: 500,
                // ml: 1,
              }}
            >
              {currentPost.author}
            </Typography>
          </BlogHeader>
        )}
        <ReactMarkdown
          components={components}
          rehypePlugins={[rehypeRaw]}
          urlTransform={(uri) => {
            // Handle reference-style images
            // console.log("uri", uri);
            if (uri.startsWith("[") && uri.endsWith("]")) {
              const refId = uri.slice(1, -1);
              return imageMap[refId] || uri;
            }
            return uri;
          }}
        >
          {content}
        </ReactMarkdown>
        <SocialIcons containerSx={{ mt: 6 }} />
      </BlogContainer>
    </Box>
  );
}
